<template>
  <div class="background-wave">
    <div class="wave wave-4">
      <img :src="require('@/assets/img/wave-10.png')" alt="" />
    </div>
    <div class="circle-2"></div>
    <div class="block__inner">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>

<style scoped></style>
