import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/main";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import BaseLayout from "@/layouts/BaseLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/en", redirect: { path: "/" } },
  {
    path: "/:locale(fr|de)?",
    component: EmptyLayout,
    beforeEnter: languageMiddleware,
    children: [
      {
        path: "",
        component: BaseLayout,
        children: [
          {
            path: "",
            name: "Home",
            component: () => import("../views/HomeView.vue"),
          },
          {
            path: "about",
            name: "About",
            component: () => import("../views/AboutView.vue"),
          },
          {
            path: "platform",
            name: "Platform",
            component: () => import("../views/PlatformView.vue"),
          },
          {
            path: "contacts",
            name: "Contacts",
            component: () => import("../views/ContactsView.vue"),
          },
          {
            path: "safety",
            name: "Safety",
            component: () => import("../views/SafetyView.vue"),
          },
          {
            path: "privacy-policy",
            name: "Privacy",
            component: () => import("../views/PrivacyView.vue"),
          },
          {
            path: "markets",
            component: EmptyLayout,
            children: [
              {
                path: "",
                name: "Markets",
                component: () => import("../views/MarketsView.vue"),
              },
              {
                path: "stock",
                name: "Stock",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "@/views/markets/StockView"
                  ),
              },
              {
                path: "commodities",
                name: "Commodities",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "@/views/markets/CommoditiesView"
                  ),
              },
              {
                path: "indices",
                name: "Indices",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "@/views/markets/IndicesView"
                  ),
              },
              {
                path: "forex",
                name: "Forex",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "@/views/markets/ForexView"
                  ),
              },
              {
                path: "currency",
                name: "Currency",
                component: () =>
                  import(
                    /* webpackChunkName: "home" */ "@/views/markets/CurrencyView"
                  ),
              },
            ],
          },
          {
            path: "auth",
            component: AuthLayout,
            children: [
              {
                path: "login",
                name: "Login",
                component: () => import("../views/auth/LoginView.vue"),
              },
              {
                path: "forgot-password",
                name: "ForgotPassword",
                component: () => import("../views/auth/ForgotView.vue"),
              },
              {
                path: "recovery",
                name: "PasswordReset",
                component: () => import("../views/auth/PasswordResetView.vue"),
              },
              {
                path: "register",
                name: "Registration",
                component: () => import("../views/auth/RegistrationView.vue"),
              },
            ],
          },
          {
            path: "",
            component: EmptyLayout,
            children: [
              {
                path: "activation/success",
                name: "Activation",
                component: () => import("../views/auth/ActivationView.vue"),
              },
              {
                path: "activation/error",
                name: "ActivationError",
                component: () =>
                  import("../views/auth/ActivationErrorView.vue"),
              },
              {
                path: "withdrawal/success",
                name: "Withdraw",
                component: () => import("../views/auth/WithdrawView.vue"),
              },
              {
                path: "withdrawal/error",
                name: "WithdrawError",
                component: () => import("../views/auth/WithdrawErrorView.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 1, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

function languageMiddleware(to, from, next) {
  const locale = to.params.locale || "en";

  i18n.locale = locale;

  document.querySelector("html").setAttribute("lang", locale);

  return next();
}

export const localePath = function (route, lang) {
  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") return route;
  return Object.assign(route, {
    params: {
      locale,
      ...route.params,
    },
  });
};

Vue.prototype.$localePath = localePath;

Vue.prototype.$switchLocalePath = function (lang) {
  const path = { ...router.currentRoute };

  let locale = lang;

  if (locale === undefined) {
    locale = i18n.locale;
  }

  if (locale === "en") locale = null;

  return Object.assign(path, {
    params: {
      locale,
    },
  });
};
